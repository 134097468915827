import { SEOHandle } from "@nasa-gcn/remix-seo";
import { SitemapEntry } from "@nasa-gcn/remix-seo/build/types";

import { SUPPORTED_LANGUAGES } from "~/config/constants";

export default function generateSitemapEntriesFor(
  route: string,
  lastmod?: string,
  changefreq: SitemapEntry["changefreq"] = "weekly",
  priority: SitemapEntry["priority"] = 0.7,
): SEOHandle {
  return {
    getSitemapEntries: async () => {
      return SUPPORTED_LANGUAGES.map((locale) => {
        const localizedRoute = locale === "en" ? route : `/${locale}${route}`;
        return { route: localizedRoute, priority, changefreq };
      });
    },
  };
}
